import styled from 'styled-components';

export const MainContainer = styled.div`
    min-height: calc(100vh );
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
`;

