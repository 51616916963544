import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import './App.css';


import Main from './pages/structure/appStructure/appStructure.component';

import { selectPrimaryColor } from './redux/theme/theme.selector';
import { setPrimaryColor } from './redux/theme/theme.actions'


class App extends React.Component {


  render() {
    const { color, setPrimaryColor } = this.props;

    return (
      <div className="app" >
        <Main color={color} />

      </div >
    );
  }
}


const mapStateToProps = createStructuredSelector({
  color: selectPrimaryColor
});

const mapDispatchToProps = dispatch => ({
  setPrimaryColor: color => dispatch(setPrimaryColor(color))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
