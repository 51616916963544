import styled from 'styled-components';

export const CarouselContainer = styled.div`
    margin-bottom: 50px;
    height: min(60vh, 40vw);
    width: min(70vh, 46vw);

    .carousel-wrapper,
    .carousel {
      height: 100%;
      width: 100% ;
    }

    .carousel > .slider-wrapper,
    .carousel > .slider-wrapper > .slider {
      height: 100%;
    }

    .image-container {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      /* background-size: cover; */
      background-size: 100% 100%;
      background-position: center;
    } 
  `



