import React from 'react';

import { AdminPageContainer } from "./admin.styles";

const AdminPage = ({ color }) => (
    <AdminPageContainer>
        ADMIN Page
    </AdminPageContainer>
);

export default AdminPage;