import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { MainContainer } from "./appStructure.styles";


import Header from '../header/header.component';
import Footer from '../footer/footer.component';

import Homepage from '../../homepage/homepage.component';
import AdminPage from "../../admin/admin.component";


const Main = ({ color }) => (
    <MainContainer bgcolor={color}>
        < Header title="Header" color={color} />

        <Switch>
            <Route exact path='/' component={Homepage} />
            <Route exact path='/admin' component={AdminPage} />
        </Switch>

        < Footer />
    </MainContainer>


);

export default Main;