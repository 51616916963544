import React from 'react';

import CustomCarousel from '../../components/common/carousel/carousel.component'
import News from '../../components/common/news/news.component'


import { HomepageContainer } from "./homepage.styles";

const Homepage = () => (

    <HomepageContainer>

        <CustomCarousel />
        <News />

    </HomepageContainer>

);

export default Homepage;