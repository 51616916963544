import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";

import { CarouselContainer } from './carousel.styles'

import { IMG_LIST_DATA } from '../../../tmp-data/homepage/imgList'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


export default () => {

    const [isShown, setIsShown] = useState(false);

    const settings = {
        showThumbs: false,
        infiniteLoop: true,
        autoPlay: true,
        interval: 4500,
        transitionTime: isShown ? 300 : 1500,
        showStatus: false,
        stopOnHover: true,
        showArrows: isShown,
        showIndicators: isShown && IMG_LIST_DATA.length > 1
    }

    return (
        <CarouselContainer
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}>
            <Carousel {...settings} className="carousel-wrapper">
                {
                    IMG_LIST_DATA.map(img => <div key={img.id} className="image-container"
                        style={{
                            backgroundImage: `url('${img.url}')`
                        }}
                    >


                    </div>)
                }
            </Carousel>
        </CarouselContainer>
    );

}