import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectNewsList } from "../../../redux/news/news.selector";
import { fetchNewsStart } from "../../../redux/news/news.actions";

import { NewsContainer, SeparatorContainer } from "./news.styles"

import ParagraphSeparator from '../../../assets/carafe.svg';




class News extends React.Component {


    replaceHtml = (text, expression, mode) => {
        return text.split(new RegExp(expression)).map((textMap, indMap) =>
            indMap % 2 === 1 ?
                (
                    mode === "BOLD" ?
                        <b key={indMap}> {textMap}</b >
                        :
                        <u key={indMap}> {textMap}</u >
                )
                :
                textMap
        )
    }


    componentDidMount() {
        this.props.fectchNewsStart();
    }

    render() {

        const { news } = this.props;

        return (
            news ?
                <NewsContainer>
                    {
                        news.map((news, index) => <div contentEditable='true' key={news.id}>
                            {
                                (index > 0) ?
                                    <SeparatorContainer>
                                        <img key="1" src={ParagraphSeparator} alt="Paragraph Separator" />
                                        <img key="2" src={ParagraphSeparator} alt="Paragraph Separator" />
                                        <img key="3" src={ParagraphSeparator} alt="Paragraph Separator" />
                                    </SeparatorContainer>
                                    :
                                    null
                            }

                            <h1>{news.title}</h1>

                            {/* <div contentEditable='true' dangerouslySetInnerHTML={{ __html: news.content }}></div> */}
                            {news.content.split("<br />").map((paragraphe, indParag) =>
                                paragraphe ?
                                    <p key={indParag}>
                                        {
                                            paragraphe.split(new RegExp('</?b>')).map((textBold, indTextBold) =>
                                                indTextBold % 2 === 1 ?
                                                    <b key={indTextBold}>{
                                                        textBold.split(new RegExp('</?u>')).map((textUnderline, indTextUnderline) =>
                                                            indTextUnderline % 2 === 1 ?
                                                                <u key={indTextUnderline}>{textUnderline}</u>
                                                                :
                                                                textUnderline
                                                        )
                                                    }</b>
                                                    :
                                                    textBold.split(new RegExp('</?u>')).map((textUnderline, indTextUnderline) =>
                                                        indTextUnderline % 2 === 1 ?
                                                            <u key={indTextUnderline}>{textUnderline}</u>
                                                            :
                                                            textUnderline

                                                    )
                                            )
                                        }
                                    </p>
                                    : <br key={indParag} />
                            )}

                        </div>)
                    }
                </NewsContainer >
                :
                null
        )
    };
}

const mapStateToProps = createStructuredSelector({
    news: selectNewsList
});

const mapDispatchToProps = dispatch => ({
    fectchNewsStart: () => dispatch(fetchNewsStart())
});


export default connect(mapStateToProps, mapDispatchToProps)(News);