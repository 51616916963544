import React from 'react';

import { FooterContainer } from "./footer.styles";

const Footer = ({ color }) => (
    <FooterContainer bgcolor={color}>
        Footer
    </FooterContainer>


);

export default Footer;