export const IMG_LIST_DATA = [
    {
        "url": "tmp-data/homepage/img1.jpg",
        "id": 1
    },
    {
        "url": "tmp-data/homepage/img2.jpg",
        "id": 2
    },
    {
        "url": "tmp-data/homepage/img3.jpg",
        "id": 3
    },
    {
        "url": "tmp-data/homepage/img4.jpg",
        "id": 4
    },
    {
        "url": "tmp-data/homepage/img5.jpg",
        "id": 5
    }

]
