import { takeLatest, call, put, all, delay } from 'redux-saga/effects';

import {
    fetchNewsFailure,
    fetchNewsSuccess
} from './news.actions';

import { NewsActionTypes } from './news.types';


export function* fetchNewsAsync() {
    try {
        console.log("fetchNewsAsync");
        yield delay(3000);

        const data = yield fetch("tmp-data/homepage/news.js");
        const data_json = yield data.json();
        yield put(fetchNewsSuccess(data_json));

        console.log("fetchNewsAsync End");
    } catch (error) {
        yield put(fetchNewsFailure(error.message));
    }
}

export function* fetchNewsStart() {
    yield takeLatest(
        NewsActionTypes.FETCH_NEWS_START,
        fetchNewsAsync
    );
}

export function* newsSagas() {
    yield all([call(fetchNewsStart)]);
}