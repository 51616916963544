import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';


import themeReducer from './theme/theme.reducer';
import newsReducer from './news/news.reducer';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['theme']
};

const rootReducer = combineReducers({
  theme: themeReducer,
  news: newsReducer
});

export default persistReducer(persistConfig, rootReducer);