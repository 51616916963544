import styled from 'styled-components';

export const NewsContainer = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  
  h1{
    width: 100%;
    text-align: center;
    height: auto;
    color: #aa5f19
  }

  p{
    text-align:justify;
  }

  img{
    height: 50px;
    margin: 25px 0;
    
  }
`

export const SeparatorContainer = styled.div`
  display: flex;
  justify-content: center;
`