import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${props => props.bgcolor};
  max-height: 100px;
  min-height: 100px;
  width: 100%;
  flex: 0;
  margin-bottom: 50px;
`;

