import { ThemeActionTypes } from './theme.types';

const INITIAL_STATE = {
  color: 'beige'
};

const themeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ThemeActionTypes.SET_PRIMARY_COLOR:
      return {
        ...state,
        color: action.payload
      };
    default:
      return state;
  }
};

export default themeReducer;