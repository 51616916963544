import { NewsActionTypes } from "./news.types";


export const fetchNewsStart = () => ({
    type: NewsActionTypes.FETCH_NEWS_START
});

export const fetchNewsSuccess = NewsMap => ({
    type: NewsActionTypes.FETCH_NEWS_SUCCESS,
    payload: NewsMap
});

export const fetchNewsFailure = errorMessage => ({
    type: NewsActionTypes.FETCH_NEWS_FAILURE,
    payload: errorMessage
});