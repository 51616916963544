import styled from 'styled-components';

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #c4c4c4;
  max-height: 100px;
  min-height: 100px;
  width: 100%;
  flex: 0;
  margin-top: 50px;
`;

