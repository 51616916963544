import React from 'react';

import { HeaderContainer } from "./header.styles";

const Header = ({title, color}) => (
    <HeaderContainer bgcolor={color}>
        {title}
    </HeaderContainer>

    
);

export default Header;